<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.name : "Новая запись графика" }} {{ data.task_id ? `(#${data.task_id})` : "" }}
      </template>
      <a-loader v-if="!loaded" />
      <div v-else>
        <v-row class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
          <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
            <div v-for="name in el.name.split('+')" :key="name">
              <a-form-model
                :ref="'field_' + name"
                v-model="data"
                :model="getFormModel([name], model)"
                :errors="errors"
                :config="{ dense: true, readonly }"
                @validate="validate($event)"
                @commit="onCommit($event)"
              />
            </div>
          </v-col>
        </v-row>
        <v-textarea
          v-if="data.service && data.service.data.description"
          label="Описание к работе"
          outlined
          auto-grow
          readonly
          hide-details="auto"
          rows="1"
          :value="data.service.data.description"
        />
      </div>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="editObject && !data.task_id && !readonly" @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id && editObject && !data.task_id && !readonly" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    object_: { type: Object, default: () => {} },

    defaults: { type: Object, default: () => {} },
    lastRow: { type: Object, default: () => {} },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.works,
      api: "/mechti/construction-schedule",
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить эту запись из графика?",
      idEdit: 0,
      loaded: false,
      suEditShow: false,
      model: [],
    };
  },
  computed: {
    model1: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        if (this.data.work_days) model.find((el) => el.name == "date_end").readonly = true;
        return model;
      },
    },
    readonly() {
      return ![-1, 100].includes(this.data.status);
    },
    editObject() {
      return this.getAccess("object.workEdit", {
        id: this.object_.id,
        access: this.object_?.object_access || [],
      });
    },
  },
  watch: {
    "data.work_days"(v) {
      if (v) {
        let d = v > 0 ? v - 1 : v;
        this.data.date_end = new Date(this.data.date_start).addDays(d).date;
      }
    },
    "data.date_start"(v) {
      if (this.data.work_days) {
        let d = this.data.work_days > 0 ? this.data.work_days - 1 : v;
        this.data.date_end = new Date(this.data.date_start).addDays(d).date;
      }
    },
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.data.object_id = this.object_.id;
          let date = this.lastRow ? this.lastRow.date_end || new Date() : new Date();
          this.data.department = this.lastRow ? this.lastRow.department : "";
          this.data.date_start = new Date(date).date;
          this.data.date_end = new Date(date).date;
          //  this.data.status = 0;
        }
      }
    },
  },
  methods: {
    onCommit(e) {
      console.log("commit", e);
      if (e.el.name == "service_id") {
        let res = JSON.parse(JSON.stringify(e.event || {}));
        this.data.name = res?.name || null;
        this.data.work_days = res?.data?.work_days || null;
        this.data.work_value = res?.data?.work_value || null;
        this.data.data.category_id = res?.category_id || null;
      }
      if (e.el.name == "department") {
      }
    },
    getModel(t = "default") {
      let config = this.m.config[t];
      let model = this.calcModelConfig(config);
      if (this.data.work_days) model.find((el) => el.name == "date_end").readonly = true;
      //return model;
      this.model = model;
      this.fillFormFromModel(model);
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.type);
      this.getModel();
      this.loaded = true;
    },
    afterRemove(data) {
      this.removDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
